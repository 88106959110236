import { Controller } from "@hotwired/stimulus"
//import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["entries", "pagination"]

scroll() {
   let next_page = this.paginationTarget.querySelector("a[rel='next']")

   if (next_page == null) {return}

   let url = next_page.href
      var body = document.body,
  html = document.documentElement
  var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)

    if (window.pageYOffset >= height - window.innerHeight - 100) {
      this.loadMore(url)
      //console.log("bottom")
    //this.loadMore(url)
  }

  //console.log(window.pageYOffset)
  }


  loadMore(url) {

        Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: (data) => {
        this.entriesTarget.insertAdjacentHTML('beforeend', data.entries)
        this.paginationTarget.innerHTML = data.pagination
//console.log(data)
      }


})

      }

    }