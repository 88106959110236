import { attachProductAttachment } from './direct_upload'

document.addEventListener("turbo:load", function (e) {
  init();
})

"use strict";
document.addEventListener("DOMContentLoaded", init);

function init() {
  $('progress').attr('value', 0);
  if($('#product_product_images').length) {
    document.getElementById("product_product_images").addEventListener("change", attachImageToProduct);
    setDraggableImage();
  }

  if($('#product_video').length) {
    document.getElementById("product_video").addEventListener("change", attachVideoToProduct);
  }
}

async function attachImageToProduct() {
  var fieldset = $('#product_product_images').parent().parent();
  fieldset.find('progress').attr('value', 0);
  setDraggableImage();

  const promises = [];

  for (file of this.files) {
    // let file = this.files[0];
    promises.push(attachProductAttachment({
      file: file,
      product_id: $('#product-id').data('product-id'),
      fieldset: fieldset,
      module: 'image',
      url: '/products/attach_direct_upload_image'
    }));
  }

  const records = await Promise.all(promises);
  
}

async function attachVideoToProduct() {
  var fieldset = $('#product_video').parent();
  fieldset.find('progress').attr('value', 0);

  let file = this.files[0];
  let result = await attachProductAttachment({
    file: file,
    product_id: $('#product-id').data('product-id'),
    fieldset: fieldset,
    module: 'video',
    url: '/products/attach_direct_upload_video'
  });
}

function setDraggableImage(){
  $( "#product-images-preview" ).sortable({
    update: function(event, ui) {
      updateImageOrder();
    }
  });
}

function getIdsOfImages() {
  var values = [];
  $('.image-card').each(function (index) {
    values.push($(this).data("img-id"));
  });

  return values;
}

function updateImageOrder(){
  $.ajax({
    url: '/products/reorder_product_images',
    type: 'POST',
    data: JSON.stringify({
      updated_ids: getIdsOfImages(),
      product_id: $('#product-id').data('product-id')
    }),
    cache: false,
    dataType: 'json',
    contentType: 'application/json',
    processData: false,
    success: function(data, status, xhr) {
      console.log(data, status);
    },
    error: function(xhr, status, error) { }
  });
}
