import { fileChecksum } from './checksum'

const createPresignedUrl = async(file, byte_size, checksum, type) => {
  let options = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      file: {
        filename: file.name,
        byte_size: byte_size,
        checksum: checksum,
        content_type: type,
        metadata: {
          'message': 'image/video for product'
        }
      }
    })
  }
  let res = await fetch('/presigned_url', options)
  if (res.status !== 200) return res
  return await res.json()
}

export const attachProductAttachment = async(productInfo) => {
  const { file, product_id, fieldset, module, url } = productInfo

  const checksum = await fileChecksum(file)
  const presignedFileParams = await createPresignedUrl(file, file.size, checksum, file.type)

  return await $.ajax({
    url: presignedFileParams.direct_upload.url,
    type: 'PUT',
    data: file,
    headers: presignedFileParams.direct_upload.headers,
    cache: false,
    contentType: 'binary/octet-stream',
    processData: false,

    xhr: function () {
      fieldset.find('progress').removeClass('d-none');

      var myXhr = $.ajaxSettings.xhr();
      if (myXhr.upload) {
        myXhr.upload.addEventListener('progress', function (e) {
          if (e.lengthComputable) {
            fieldset.find('progress').attr({
              value: e.loaded,
              max: e.total,
            });
          }
        }, false);
      }
      return myXhr;
    },
    success: async function (data, status, xhr) {
      await attach_direct_upload(module, fieldset, url)
    },
    error: function(xhr, status, error) { }
  }).then(function(response){
    console.log("getRecord response: "+JSON.stringify(response));
    return response['data'];
  });

  async function attach_direct_upload(module, fieldset, url){
    return await $.ajax({
      url: url,
      type: 'POST',
      data: JSON.stringify({
        product_id: product_id,
        file: presignedFileParams.blob_signed_id,
      }),
      cache: false,
      dataType: 'json',
      contentType: 'application/json',
      processData: false,
      success: function(data, status, xhr) {
        if(module === 'image'){
          update_uploaded_image(data, fieldset);
        }else if(module === 'video') {
          update_uploaded_video(data, fieldset);
        }
      },
      error: function(xhr, status, error) { }
    }).then(function(response){
      console.log("getRecord response: "+JSON.stringify(response));
      return response['data'];
    });
  }

  function update_uploaded_image(data, fieldset){
    let parent = $('#product-images-preview');
    let content = $('[data-role="image-preview"]')
        .children()
        .clone(true, true);

    content.find('img').attr('src', data.image_url);
    content.find('a').attr('href', data.delete_url);
    content.attr('data-img-id', data.img_id);

    if(parent.find('.image-card').length){
      parent.find('.image-card').last().after(content);
    }else {
      parent.prepend(content);
    }

    $('#product_product_images').val('');
    fieldset.find('progress').attr('value', 0);
  }

  function update_uploaded_video(data, fieldset){
    let content = $('#video-preview');

    content.find('source').each(function (){
      $(this).attr('src', data.video_url);
    });

    content.find('video')[0].load();

    $('#product_video').val('');
    fieldset.find('progress').attr('value', 0);
  }
}
